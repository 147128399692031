#about {
    font-family: "Poppins", sans-serif;
    position: relative;
    margin: 10px 300px;
  }
  #about .main-container {
    font-family: "Poppins", sans-serif;
    border-radius: 15px;
    font-weight: 700;
  }
  #about .main-container:hover {
    transition: background-color 0.8s ease;
    color: #003366;
    font-weight: 700;
  }
  #about .main-container h1 {
    color: #ff5500;
    font-size: 2.75rem;
  }

  #about .main-container span {
    color: #003366;
  }
  #about .main-container:hover span {
    color: #003366;
  }
  #about .card-container{
    margin: 10px 0;
    background: #fcfcfc;
   box-shadow: 13px 13px 20px #e0dfdf, -13px -13px 20px #e0dfdf; 
}
  .about-info {
    /* text-transform: uppercase; */
    color: #003366;
    font-size: 0.9rem;
   
  }
  .about-info  .main-container{
    border-radius: 50%;
  }
  
  .box-container {
    font-family: "Poppins", sans-serif;
    background-color: #003366;
    border-radius: 15px;
    color: #fff;
  }
  .box-container:hover {
    transition: background-color 0.8s ease;
    background-color: #ff5500;
    color: #003366;
  }
  .box-container h2 {
    color: #ff5500;
  }
  
  .box-container:hover h2 {
    color: #fff;
  }

  #about .card-title{
    color: #ff5500;
    font-weight: 500;
  }
  #about .card-description{
    color: #003366;
    font-weight: 500;
  }
  @media screen and (max-width: 769px) {
    #about {
      background-size: 100% 90%;
      margin: 10px 100px;
    }
    #about .image {
      margin-top: 0px;
    }
    #about .card-container{
        margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 426px) {
    #about {
      background-size: 100% 95%;
      margin: 10px 30px;
    }
    #about .image {
      margin-top: 0px;
    }
    #about .main-container {
      margin-bottom: 10px;
    }
    
      #about .card-container{
        margin: 10px;

    }
  }
  