.services{
   margin: 20px 10px;
   font-family: 'Poppins', sans-serif;
}
.card-container{
    margin: 10px 0;
    background: #fff;
   box-shadow: 13px 13px 20px #cbced1, -13px -13px 20px #ecf0f3; 
}
.card-title{
    margin: 10px 30px !important;
    height: 50px;
    font-size: 15px;
    cursor: pointer;
    transition: transform 0.3s ease;
}
.card-title:hover{
   transform: scale(1.2)
}

.card-description{
    font-size: 13px;
}

.image-container .icons{
    margin-top: 10px;
    padding: 10px;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background-color: #ff5500;
}
.services p{
    font-weight: 500;
}
/* 
#002347 , #003366 */