

.home-bg{
    font-family: 'Poppins', sans-serif;
    background-color: 	#ff5500 !important;
    margin: 0;
    padding: 0;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.image{
    margin: 0;
    padding: 0;
}
.home-bg h1,
.home-bg h5{
    color: #002347;
}