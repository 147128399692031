#techstack{
    font-family: "Poppins", sans-serif;
}

#techstack .img{
    height: 45px;
}
#techstack .sub-container{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
#techstack .sub-container div{
    margin: 5px;
    height: 75px;
    width: 75px;
    border-radius: 50%;
    box-shadow: 4px 4px 20px #e0dfdf, -4px -4px 20px #e0dfdf;
    display: flex;
    justify-content: center;
    align-items: center;
    
}