.footer-container{
    font-family: "Poppins", sans-serif; 
    text-align: left;
}
.footer-container .img {
    height: 20px;
  }
  .footer-content {
    padding: 10px 0;
    color: #ff5500 !important;
    background-color: #002347;
  }
  .footer-container  a{
    text-decoration: none !important;
    color: #002347;
  }
  
  .footer-container h5{
    color: #ff5500;
  }