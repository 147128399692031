/* Navbar.css */

  .navbar-head{
    background-color:#ff5500 !important;
    font-family: 'Poppins', sans-serif;
    position: fixed !important;
    width: 100%;
    z-index: 1;
  }

  .navbar-head .title{
    color: #fff;
    font-weight: 700;
    font-size: 20px;
  }
  
  .nav-items{
    color: #fff !important;
    font-weight: 700;
    
  }
  .navbar-nav .nav-link{
    color: #fff !important;
  }
  .custom-navbar .navbar-nav .nav-link {
    margin-right: 15px; /* Adjust the spacing between nav links */
  }
  
  /* Add media query for responsiveness */
  @media (max-width: 768px) {
    .custom-navbar .navbar-nav {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .custom-navbar .navbar-nav .nav-link {
      margin-right: 0;
      margin-bottom: 10px; /* Adjust the spacing between nav links for small screens */
    }
  }
  